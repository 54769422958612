.Home .lander {
    padding: 80px 0;
    /* text-align: center; */
  }
  
  .Home .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
  }

  .jumbotron {
    background-image: url(images/iStock-1209081213.jpg);
    background-size: cover;
    background-position: center;
    padding-top: 20%;
    padding-bottom: 20%;
  }

  .jumbotron h1 {
    font-size: 600%;
  }

  .jumbotron p {
    font-size: 200%;
  }

  .jumbotron h1, p {
    color: #ffffff73;
    text-align: center;
  }

  .row p {
    text-align: left;
    color: black;
  }
